import Pusher from 'pusher-js';

import Settings from 'helpers/settings';
import AuthHelper from 'helpers/auth_helper';
import store from '../store/configureStore';
import {
  fetchNotificationsSuccess, fetchUnreadConversationsCount, fetchUnreadNotificationsCountSuccess,
} from '../store/actions'
import NotificationHelpers from 'helpers/notification_helpers';
import WebNotificationHelper from 'helpers/web_notification_helpers';

const SECRET_ALIAS_ID = AuthHelper.currentUser().secret_alias_id;

class SCPusherSingleton {
  constructor() {
    if (!SECRET_ALIAS_ID) return null;

    if (!SCPusherSingleton.instance) {
      this.pusher = new Pusher(Settings.SWIPECAST_PUSHER_APP_KEY, {
        // encrypted: true,
        cluster: Settings.SWIPECAST_PUSHER_APP_CLUSTER,
        channelAuthorization: {
          // required to send events
          endpoint: `${Settings.SWIPECAST_BACKEND}/pusher/sessions`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthHelper.currentAuthToken()
          }
        }
      });
      this.pusher.subscribe(SECRET_ALIAS_ID);
      SCPusherSingleton.instance = this;
    }

    return SCPusherSingleton.instance;
  }

  getInstance() {
    return this.pusher;
  }

  getChannel(channelName) {
    if (!this.pusher) return console.error('Trying to get channel from pusher which is initialized')
    return this.pusher.channel(channelName);
  }

  subscribe(channelName) {
    if (!this.pusher) return console.error('Trying to subscribe pusher which is initialized')
    const existedChannel = this.getChannel(channelName);
    return existedChannel || this.pusher.subscribe(channelName);
  }

  unsubscribe(channelName) {
    const existedChannel = this.getChannel(channelName);
    if (existedChannel) {
      this.pusher.unsubscribe(channelName);
      return true;
    }

    console.log('Trying to unsubscribe from channel which does not exist');
    return false;
  }

  // main channel binding. which is always subscribed for logged in users
  // if you want to create secondary channels use bind on SCPusher.subscribe and SCPusher.getChannel
  bind(eventName, handler) {
    if (!this.pusher) return console.error('Trying to bind pusher which is not set yet')

    // As we don't have a perfect SPA implementation yet, there's a risk of
    // having multiple bindings. So, unbinding first.
    const channel = this.getChannel(SECRET_ALIAS_ID);
    if (channel) {
      channel.unbind(eventName);
      channel.bind(eventName, handler);
    }
  }

  unbind(eventName) {
    const channel = this.getChannel(SECRET_ALIAS_ID);
    return channel.unbind(eventName);
  }

  bindProfileUpdate() {
    this.bind('profile_update', AuthHelper.loadFromBackend);
  }

  bindNotifications() {
    this.bind('notification', async notification => {
      const { notifications, unreadNotificationsCount } = store.store.getState();

      try {
        const texts = NotificationHelpers.notificationText(notification);
        const text = texts.map(i => i.text).join('');
        await WebNotificationHelper.triggerNotification('Swipecast', text);
      } catch (error) {
        console.error(error.message);
      }

      store.store.dispatch(fetchNotificationsSuccess([{ ...notification }, ...notifications.data]));

      store.store.dispatch(fetchUnreadNotificationsCountSuccess(unreadNotificationsCount.data + 1));
    });
  }

  bindUnreadConversations() {
    this.bind('unread_conversations_count', () => {
      // we should give some time to perform read conversation request in case user is currently
      // on the chat page to avoid header unread conversation flickering
      setTimeout(() => store.store.dispatch(fetchUnreadConversationsCount()), 2000)
    })
  }
}

const SCPusher = new SCPusherSingleton();
Object.freeze(SCPusher);

export default SCPusher;
