const required = value => (value ? undefined : 'This field cannot be empty');
const mustBeNumber = value => (isNaN(value) ? 'Must be a number' : undefined);
const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;
const maxValue = max => value =>
  isNaN(value) || value <= max ? undefined : `Should be less than ${max}`;

// https://stackoverflow.com/questions/51368316/redux-forms-sometimes-ends-up-with-register-unregister-infinite-loop
// WARNING! Don't pass arguments to this function directly in render !!!
// a new function should be created basis on this function, otherwise Maximum update depth exceeded can occur
// example
// const minLength6 = useCallback(() => minLength(6), [])
// and use minLength6 as a field level validation
const minLength = min => value =>
  !value || value.length < min
    ? `Should be longer than ${min} characters`
    : undefined;
const maxLength = max => value =>
  !value || value.length > max
    ? `Should be shorter than ${max} characters`
    : undefined;

const emailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const isEmail = value => {
  if (!value) return undefined;
  return emailRegexp.test(value) ? undefined : 'Please provide a valid email';
};

const validPW = value => {
  const matchLatter = /(.*[a-zA-Z].*)/.test(value);
  const matchDigit = /(.*\d.*)/.test(value);
  // const matchSymbol = /(.*\W.*)/.test(value)
  const match = matchLatter && matchDigit;

  return match
    ? undefined
    : 'Password must include at least 1 letter and 1 number.';
};

const onlyNumbers = value => {
  if (!value) return undefined;
  const letterNumberRegex = /^[0-9]+$/;
  return letterNumberRegex.test(value) ? undefined : 'Can only contain numbers';
};

const isUrl = value => {
  if (!value) return undefined;
  const urlRegex = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g;
  return urlRegex.test(value) ? undefined : 'Please provide a valid URL';
};

const notIncludeSpaces = value => {
  if (!value) return undefined;
  return value.includes(' ') ? 'Should not include spaces' : undefined;
};

const validPromo = value => {
  if (!value) return undefined;
  if (value.length < 10) return 'Promo code is too short';
  if (value.length > 30) return 'Promo code is too long';
  return undefined
}

const simpleMemoize = fn => {
  let lastArg;
  let lastResult;
  return arg => {
    if (arg !== lastArg) {
      lastArg = arg;
      lastResult = fn(arg);
    }
    return lastResult;
  };
};

const normalizeUpper =  value => value && value.toUpperCase()

const ffHelpers = {
  normalizeUpper,
  notIncludeSpaces,
  simpleMemoize,
  emailRegexp,
  isUrl,
  onlyNumbers,
  validPW,
  isEmail,
  validPromo,
  required,
  mustBeNumber,
  minValue,
  maxValue,
  minLength,
  maxLength
};

export default ffHelpers;
