import styled from 'styled-components';
import ReduxField from 'components/reusable/input/components/ReduxField';

export const StyledForm = styled.form`
  border-radius: inherit;
  position: relative;
  @media (max-width: 768px) {
    height: 100vh;
    height: 100dvh;
    overflow-y: auto;
  }
`;

export const StyledField = styled(ReduxField)`
  text-align: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
