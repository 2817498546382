import { useSelector } from 'react-redux';

import { signUpStepsData } from 'components/modals/v2/auth/sign_up_additional_details_modal/helpers/signup_steps_helper'

const sharedPhotosSelector = state => state.sharedPhotosV2;
const sharedVideosSelector = state => state.sharedVideosV2;
const sharedFilesSelector = state => state.sharedFilesV2;
const sharedLinksSelector = state => state.sharedLinksV2;
const zoomMeetingsSelector = state => state.zoomMeetingsV2;
const profileFormSelector = state => state.form[signUpStepsData.profile.form];
const unreadConversationsCountSelector = state => state.unreadConversationsCount;
const unreadNotificationsCountSelector = state => state.unreadNotificationsCount;
const formCreateJobSummarySelector = state => state.form.createJobSummary;

export const useFormCreateJobSummarySelector = shallowEqual => useSelector(formCreateJobSummarySelector, shallowEqual);
export const useSharedPhotosSelector = shallowEqual => useSelector(sharedPhotosSelector, shallowEqual);
export const useSharedVideosSelector = shallowEqual => useSelector(sharedVideosSelector, shallowEqual);
export const useSharedFilesSelector = shallowEqual => useSelector(sharedFilesSelector, shallowEqual);
export const useSharedLinksSelector = shallowEqual => useSelector(sharedLinksSelector, shallowEqual);
export const useZoomMeetingsSelector = shallowEqual => useSelector(zoomMeetingsSelector, shallowEqual);
export const useProfileFormSelector = shallowEqual => useSelector(profileFormSelector, shallowEqual);
export const useUnreadConversationsCountSelector = shallowEqual => useSelector(unreadConversationsCountSelector, shallowEqual);
export const useUnreadNotificationsCountSelector = shallowEqual => useSelector(unreadNotificationsCountSelector, shallowEqual);
