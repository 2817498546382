import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from 'helpers/colors';
import Button from 'components/reusable/button';
import Input from 'components/reusable/input';
import ReduxField from 'components/reusable/input/components/ReduxField';

export const StyledInput = styled(Input)`
  text-align: center;
`;

export const StyledReduxField = styled(ReduxField)`
  text-align: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  background: ${colors.light};
  padding: 32px 56px;
  display: flex;
  min-width: 640px;
  max-width: 640px;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  @media (max-width: 768px) {
    min-width: 100%;
    width: 100%;
    padding: 16px;
    height: 100vh;
    height: 100dvh;
    overflow-y: auto;
  }
`;

export const WrapperWithProgressIndent = styled(Wrapper)`
  @media (max-width: 768px) {
    padding: 48px 16px 16px;
    min-height: 100%;
    height: fit-content;
  }
`;

export const buttonStyles = css`
  min-height: 48px;
  border-radius: 30px;
  display: flex;
  height: 48px;
  @media (max-width: 768px) {
    height: 40px;
    min-height: 40px;
  }
`;

export const PrimaryButton = styled(props => (
  <Button type="primary" {...props} />
))`
  ${buttonStyles};
  width: 100%;
`;

export const GuestButton = styled(Button)`
  ${buttonStyles};
  box-shadow: inset 0px 0px 2px rgba(31, 30, 30, 0.16);
  background-color: ${colors.gray[100]};
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
`;
