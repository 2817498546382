import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { StyledField, StyledForm } from 'components/modals/v2/auth/sign_up_additional_details_modal/components/styled'
import SignUpAdditionalStepsProgress
  from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_additional_steps_progress'
import { WrapperWithProgressIndent } from 'components/modals/v2/auth/sign_up_modal/sign_up_modal_v3/styled'
import Settings from 'helpers/settings'
import SignUpAdditionalStepsCloseButton
  from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_additional_steps_close_button'
import Indent from 'components/reusable/indent'
import SignUpAdditionalStepsHeading
  from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_additional_steps_heading'
import Error from 'components/reusable/error'
import SignUpAdditionalStepsButtonsToolbar
  from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_additional_steps_buttons_toolbar'
import { signUpStepsData } from 'components/modals/v2/auth/sign_up_additional_details_modal/helpers/signup_steps_helper'
import Text from 'components/reusable/text'

const tip = (
  <Text size={12}>
    Please fill in at least one of the fields so we can verify you.
  </Text>
)

function SignUpWorkStepView({
  isExtendedFields = true,
  isSubmitDisabled,
  handleSubmit,
  serverError,
  onSubmit,
  progress,
  onClose,
  igError,
  onBack,
  index,
}) {
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <SignUpAdditionalStepsProgress value={progress}/>

      <WrapperWithProgressIndent>
        {Settings.signUpAdditionalStepsSkippable ? <SignUpAdditionalStepsCloseButton onClick={onClose}/> : null}
        <Indent top={24}/>
        <SignUpAdditionalStepsHeading tip={isExtendedFields ? tip : null} label="Job details" index={index}/>
        <Indent top={24}/>
        <Field
          name={signUpStepsData.ig.field}
          component={StyledField}
          error={igError}
          placeholder="@username"
        />

        {isExtendedFields ? <>
          <Indent top={24}/>
          <Field
            name={signUpStepsData.website.field}
            component={StyledField}
            placeholder="https://website.com"
            autoComplete="url"
          />
        </> : null}

        {isExtendedFields ? <>
          <Indent top={24}/>
          <Field
            name={signUpStepsData.company.field}
            component={StyledField}
            placeholder="Company Name"
            autoComplete="organization"
          />

          <Indent top={24}/>
          <Field
            name={signUpStepsData.jobTitle.field}
            component={StyledField}
            placeholder="Job Title"
            autoComplete="organization-title"
          />
        </> : null}

        <Indent top={8}/>
        <Error textAlign={'center'}>{serverError}</Error>
        <Indent top={36}/>
        <SignUpAdditionalStepsButtonsToolbar onBack={onBack} isSubmitDisabled={isSubmitDisabled}/>
      </WrapperWithProgressIndent>
    </StyledForm>
  )
}

const validate = (data, props) => {
  const errors = {}
  const allThreeEmpty = !data[signUpStepsData.company.field]
                        && !data[signUpStepsData.website.field]
                        && !data[signUpStepsData.ig.field]

  if (props.isExtendedFields && allThreeEmpty) {
    const msg = 'The organization, website, or Instagram account is required'
    errors[signUpStepsData.company.field] = msg
    errors[signUpStepsData.website.field] = msg
    errors[signUpStepsData.ig.field] = msg
  }

  if (!props.isExtendedFields &&
      !data[signUpStepsData.ig.field]) errors[signUpStepsData.ig.field] = 'Instagram is required'

  //if (data[signUpStepsData.company.field] &&
  //    !data[signUpStepsData.jobTitle.field]) errors[signUpStepsData.jobTitle.field] = 'The job position is required if an organization is provided'

  return errors
}

export default reduxForm({
  validate,
  form: signUpStepsData.company.form,
  destroyOnUnmount: false,
})(SignUpWorkStepView)
