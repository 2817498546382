import {
  useSharedFilesSelector,
  useSharedLinksSelector,
  useSharedPhotosSelector,
  useSharedVideosSelector
} from 'store/selectors';
import { fileTypesEnum } from 'components/modals/v2/chat_shared_modal/file_types_enum';

const useSharedFilesHeader = () => {
  const sharedPhotosSlice = useSharedPhotosSelector();
  const sharedVideosSlice = useSharedVideosSelector();
  const sharedFilesSlice = useSharedFilesSelector();
  const sharedLinksSlice = useSharedLinksSelector();

  const headerItems = [
    { label: fileTypesEnum.photos, count: sharedPhotosSlice.meta.count },
    { label: fileTypesEnum.videos, count: sharedVideosSlice.meta.count },
    { label: fileTypesEnum.files, count: sharedFilesSlice.meta.count },
    { label: fileTypesEnum.links, count: sharedLinksSlice.meta.count }
  ];

  return headerItems;
};

export default useSharedFilesHeader;
