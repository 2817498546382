import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';

const Container = styled.div`
  position: relative;
  cursor: pointer;
`;

const SelectWrapper = styled.div`
  -webkit-appearance: none;
  position: relative;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: ${colors.light};
  box-shadow: 0px 0px 4px rgba(31, 30, 30, 0.24);
  ${({ error }) => error && 'box-shadow: 0px 0px 4px rgba(255, 59, 48, 0.8);'}
  border-radius: 30px;
  border: none;
  font-family: 'Gotham Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  z-index: 3;
`;

const Row = styled.div`
  position: relative;
  padding-right: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 18px;
`;

const VariantsWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  background: ${colors.light};
  box-shadow: 0px 0px 8px rgba(31, 30, 30, 0.08);
  padding: 20px 0 8px 0;
  border-radius: 0 0 20px 20px;
  border: none;
  font-weight: 400;
  font-size: 1rem;
  max-height: 200px;
  height: 200px;
  overflow-y: auto;
  z-index: 2;
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 500px) {
    max-height: 140px;
  }
`;

const Fade = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 59%,
    rgba(255, 255, 255, 1) 83%
  );
  position: absolute;
`;

const ItemContainer = styled.div`
  position: relative;
  cursor: pointer;
  padding: 4px 0 8px 16px;
  &:hover {
    background-color: ${colors.gray[50]};
    transition: all 0.15s;
  }
  &:hover ${Fade} {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 59%,
      rgba(247, 247, 247, 1) 83%
    );
  }
`;

const Image = styled.div`
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 1;
`;

function useOutClick(elRef, isActive, setActive) {
  const handleOutsideClick = e => {
    const elem = e.target.closest(null);
    if (!elem || elRef.current !== elem) {
      setActive(false);
    }
  };
  useEffect(() => {
    if (isActive) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });
}
function CustomSelect({
  value = '',
  onChange,
  placeholder,
  options = [],
  getOptionLabel,
  error
}) {
  const [isActive, setActive] = useState(false);
  const selectRef = useRef(null);
  useOutClick(selectRef, isActive, setActive);
  const openSelect = () => {
    setActive(!isActive);
  };

  const showVariants = () => {
    const handleOption = option => {
      onChange(option.prefix);
      setActive(false);
    };
    return (
      <VariantsWrap
        onMouseDown={e => {
          e.stopPropagation();
        }}
      >
        {options &&
          getOptionLabel &&
          options.map((option, i) => (
            <ItemContainer key={i} onClick={() => handleOption(option)}>
              {getOptionLabel(option)}
              <Fade />
            </ItemContainer>
          ))}
      </VariantsWrap>
    );
  };
  return (
    <Container onClick={openSelect}>
      <SelectWrapper ref={selectRef} error={error}>
        <Row>
          {value || [placeholder]}
          <Image>{svgIcons.arrowBottom(colors.gray[300])}</Image>
        </Row>
      </SelectWrapper>
      {isActive && showVariants()}
    </Container>
  );
}

export default CustomSelect;
