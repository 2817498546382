import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import Text from 'components/reusable/text';
import Image from 'components/reusable/image';
import Indent from 'components/reusable/indent';

const ManipulatorWrapper = styled.div`
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ touched }) => (touched ? colors.primary[500] : colors.gray[100])};
  border-radius: 25px;
  background-color: ${({ touched }) => (touched ? colors.primary[500] : 'transparent')};
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:first-child {
    padding-left: 8px;
  }
  &:last-child {
    padding-right: 8px;
  }
`;

const StyledValue = styled(Text)`
  min-width: 18px;
  text-align: center;
`;

const StyledSvg = styled.svg`
  fill: ${({ isSelected }) => (isSelected ? colors.light : colors.gray[500])};
`;

const NumberManipulator = ({ value, onChange, min = 0, max = 10 }) => {
  const [touched, setTouched] = useState(value > 0);
  const inlineColor = touched ? colors.light : colors.gray[500];

  const handleChange = useCallback(
    v => {
      setTouched(true);
      const isValid = v >= min && v <= max;
      if (isValid) onChange(v);
    },
    [min, max, onChange]
  );

  const onIncrement = useCallback(() => handleChange(value + 1), [handleChange, value]);
  const onDecrement = useCallback(() => handleChange(value - 1), [handleChange, value]);

  return (
    <ManipulatorWrapper touched={touched}>
      <IconWrapper onClick={onDecrement}>
        <StyledSvg
          isSelected={touched}
          width="16"
          height="2"
          viewBox="0 0 16 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="2" rx="1" />
        </StyledSvg>
      </IconWrapper>

      <Indent right={11} />
      <StyledValue color={inlineColor} size={16} weight={500}>
        {value}
      </StyledValue>
      <Indent right={11} />
      <IconWrapper onClick={onIncrement}>
        <StyledSvg
          isSelected={touched}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0" y="7" width="16" height="2" rx="1" />
          <rect x="9" y="8" width="16" height="2" rx="1" transform="rotate(90 13 4)" />
        </StyledSvg>
      </IconWrapper>
    </ManipulatorWrapper>
  );
};

export default NumberManipulator;
