import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash.get'

import SignUpProfileStep
  from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_profile_step'
import {
  signUpStepsData,
} from 'components/modals/v2/auth/sign_up_additional_details_modal/helpers/signup_steps_helper'
import SignUpIntentionStep
  from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_intention_step'
import SignUpLocationStep
  from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_location_step'
import SignUpPhoneStep from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_phone_step'
import SignUpWorkStep from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_work_step'
import { actions } from 'classes/sc_ga4'
import AuthHelper from 'helpers/auth_helper'
import { useProfileFormSelector } from 'store/selectors'

const profileStep = {
  component: SignUpProfileStep,
  name: signUpStepsData.profile.field,
  event: actions.userCreateFunnel.profile,
}
const intentionStep = {
  component: SignUpIntentionStep,
  name: signUpStepsData.intention.field,
  event: actions.userCreateFunnel.intention,
}
const locationStep = {
  component: SignUpLocationStep,
  name: signUpStepsData.location.field,
  event: actions.userCreateFunnel.location,
}
const phoneStep = {
  component: SignUpPhoneStep,
  name: signUpStepsData.phone.field,
  event: actions.userCreateFunnel.phone,
}
const workStep = {
  component: SignUpWorkStep,
  name: signUpStepsData.company.field,
  event: actions.userCreateFunnel.company,
}

const buildProfileStep = () => {
  if ((AuthHelper.currentUser()?.profiles || []).length === 0)
    return [profileStep]
  return []
}

const uncertainProfiles = ['Other', 'Photographer']

const useSteps = step => {
  const profileForm = useProfileFormSelector()
  const profile = get(profileForm, `values.${signUpStepsData.profile.field}`)

  const defaultSteps = useCallback(profile => {
    return [
      ...buildProfileStep(),
      workStep,
      locationStep,
      phoneStep,
    ]
  }, [])

  let signUpSteps = defaultSteps(profile)

  if (uncertainProfiles.includes(profile))
    signUpSteps = [
      ...signUpSteps.slice(0, 1),
      intentionStep,
      ...signUpSteps.slice(1),
    ]

  const currentStep = signUpSteps[step]
  const isLastStep = step + 1 >= signUpSteps.length

  return { signUpSteps, currentStep, isLastStep }
}

export default useSteps
