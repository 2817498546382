import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { Links, Buttons } from '../../styled';
import { colors } from 'helpers/colors';
import AuthHelper from 'helpers/auth_helper';
import OutlinedButton from '../outlined_button';
import UserBlock from '../user_block';
import HamburgerMenuDropdown from 'components/reusable/legacy/header/hamburger_menu_dropdown';
import Flex from 'components/reusable/flex';
import NotificationDropdown from 'components/views/logged_in/notifications/notification_dropdown';
import LoadingScreen from 'components/reusable/legacy/other/loading_screen';
import HeaderButton from '../header_button/header_button';
import { getJobsPath, routes } from 'helpers/router_helper';
import SvgCaseSolid from 'components/reusable/svg/svg_case_solid';
import SvgBellSolid from 'components/reusable/svg/svg_bell_solid';
import SvgEnvelopeSolid from 'components/reusable/svg/svg_envelope_solid';
import SvgPeopleSolid from 'components/reusable/svg/svg_people_solid';
import SvgHeartSolid from 'components/reusable/svg/svg_heart_solid';
import QueryHelpers from 'helpers/query_helpers';
import { useUnreadConversationsCountSelector, useUnreadNotificationsCountSelector } from 'store/selectors'

const Container = styled(Flex)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const ButtonWrap = styled.div`
  @media (max-width: 985px) {
    display: none;
  }
`;

const LoggedInHeaderRightItems = ({
  rootProps,
  pressTab,
  notifications,
  isModalVisible,
  setModalVisible,
  isMenuOpen,
  closeHamburger
}) => {
  const unreadNotificationsCount = useUnreadNotificationsCountSelector()?.data || 0
  const unreadConversationsCount = useUnreadConversationsCountSelector()?.data || 0

  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const activateLoading = () => setLoading(true);
  const disableLoading = () => setLoading(false);

  const jobsActive =
    [
      routes.createProject.redirectUrl(),
      routes.jobs.my.redirectUrl(),
      routes.jobs.list.redirectUrl()
    ].includes(location.pathname) || QueryHelpers.getNavActiveness('jobs');

  const searchActive = [routes.network.talents.redirectUrl('all')].includes(
    location.pathname
  );

  const boardActive = [routes.boards.redirectUrl()].includes(location.pathname);

  const chatActive = [routes.chat.redirectUrl()].some(url =>
    location.pathname.includes(url)
  );

  return (
    <Container dir="column">
      <Links wrap="nowrap">
        {!AuthHelper.logged_in_waitlisted && (
          <HeaderButton
            active={searchActive}
            to={routes.network.talents.redirectUrl('all')}
            label="Browse Talent"
            Icon={SvgPeopleSolid}
          />
        )}

        <HeaderButton
          active={jobsActive}
          to={getJobsPath()}
          label="Jobs"
          Icon={SvgCaseSolid}
        />

        <HeaderButton
          active={chatActive}
          to={routes.chat.redirectUrl()}
          label="Messages"
          counter={unreadConversationsCount || 0}
          Icon={SvgEnvelopeSolid}
        />

        <HeaderButton
          onClick={() => pressTab('notifications')}
          label="Notifications"
          counter={unreadNotificationsCount || 0}
          active={isModalVisible.notifications}
          Icon={SvgBellSolid}
        />

        <HeaderButton
          active={boardActive}
          to={routes.boards.redirectUrl()}
          label="Favourites"
          Icon={SvgHeartSolid}
        />

        <Buttons wrap="nowrap">
          {AuthHelper.canCreateProject && (
            <ButtonWrap>
              <OutlinedButton
                children="Post a Job"
                color={colors.primary[500]}
                width={136}
                onClick={() => pressTab('create_project')}
              />
            </ButtonWrap>
          )}
          <UserBlock openHamburger={() => pressTab('hamburger')} />
        </Buttons>
      </Links>
      <HamburgerMenuDropdown
        cancelHamburger={closeHamburger}
        activated={isMenuOpen}
      />
      <LoadingScreen loading={isLoading} />
      <NotificationDropdown
        rootProps={rootProps}
        activateLoading={activateLoading}
        disableLoading={disableLoading}
        activated={isModalVisible.notifications}
        notifications={
          notifications.data && notifications.data.length > 0
            ? notifications
            : [null]
        }
        cancelNotifications={() =>
          setModalVisible({ notifications: false, login: false })
        }
      />
    </Container>
  );
};

export default LoggedInHeaderRightItems;
