import React, { useCallback, useState } from 'react';
import styled from 'styled-components'

import Indent from 'components/reusable/indent';
import Text from 'components/reusable/text';
import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';
import usePlacementDefinition from 'components/complex/tip/hooks/use_placement_definition';
import { Wrapper, MessageCorner, TipWrapper } from './styled';
import Button from 'components/reusable/button'

const Tip = (
  {
    label,
    desc,
    children,
    className,
    xAxis = 'center',
    yAxis = 'bottom',
    trigger = svgIcons.info(colors.primary[500], { width: 16 })
  },
  wrapperRef
) => {
  const { xOffset, definePlacement } = usePlacementDefinition(wrapperRef);
  const [hoverTipShown, setHoverTipShown] = useState(false);
  const [clickTipShown, setClickTipShown] = useState(false) // necessary for MR
  const onCloseTip = useCallback(() => setHoverTipShown(false), []);
  const onShowTip = useCallback(() => setHoverTipShown(true), []);
  const toggleClickTip = useCallback(() => {
    setClickTipShown((prev) => !prev)
    setHoverTipShown(false)
  }, []);
  const tipShown = hoverTipShown || clickTipShown;

  if (!desc && !children) return null;

  return (
    <Wrapper onMouseOver={onShowTip} onMouseLeave={onCloseTip} className={className}>
      <Button type='semantic-wrapper' onClick={toggleClickTip}>
        {trigger}
      </Button>

      {tipShown && (
        <TipWrapper $yAxis={yAxis} $xAxis={xAxis} ref={definePlacement} xOffset={xOffset}>
          {children || (
            <>
              <Text size={12} weight={700}>
                {label}
              </Text>
              <Indent bottom={4} />
              <StyledText size={12} weight={400}>
                {desc}
              </StyledText>
            </>
          )}
        </TipWrapper>
      )}

      {tipShown && <MessageCorner $yAxis={yAxis} />}
    </Wrapper>
  );
};

const StyledText = styled(Text)`
  white-space: pre-wrap
`

export default React.forwardRef(Tip);
