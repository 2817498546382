import React, { useCallback, useContext } from 'react';

import {
  PrimaryButton,
  Wrapper,
  StyledInput,
  InnerWrapper
} from 'components/modals/v2/auth/sign_up_modal/sign_up_modal_v3/styled';
import { colors } from 'helpers/colors';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import { ModalContext } from 'contexts/contexts';
import Flex from 'components/reusable/flex';
import Error from 'components/reusable/error';
import SignUpAdditionalStepsCloseButton from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_additional_steps_close_button';
import Link from 'components/reusable/link';
import { modalEnum } from 'components/modals/v2/modal';

const SignInByEmail = ({
  onClose,
  handleSubmit,
  error,
  password,
  isLoading,
  setPW,
  signUpSection,
  onForgotPassword,
  email,
  setEmail
}) => {
  const modalContext = useContext(ModalContext);
  const onShowSignUp = useCallback(() => modalContext.onOpenModal(modalEnum.signUp), []);

  return (
    <Wrapper>
      <SignUpAdditionalStepsCloseButton onClick={onClose} />
      <InnerWrapper>
        <Text textAlign="center" size={24} weight={500} color={colors.gray[700]}>
          LOG IN
        </Text>

        <Indent top={40}>
          <StyledInput placeholder="Email" value={email} onChange={setEmail} />
        </Indent>

        <Indent top={24}>
          <StyledInput
            type="password"
            placeholder="Password"
            value={password}
            onChange={setPW}
            autoComplete={'current-password'}
          />
        </Indent>

        <Indent top={4} />
        <Error textAlign="center">{error}</Error>
        <Indent top={8} />

        <Flex dir="row" jc="space-between">
          {/*TODO remove after uncommenting next lines*/}
          <div />
          {/*<Flex dir="row" as="start" ai="flex-end">*/}
          {/*  <StyledInput type="checkbox" version={2} />*/}
          {/*  <Indent right={8} />*/}
          {/*  <Text size={16}>Remember me</Text>*/}
          {/*</Flex>*/}

          <Link onClick={onForgotPassword}>Forgot password?</Link>
        </Flex>

        <Indent top={45} />

        <PrimaryButton isDisabled={isLoading} onClick={handleSubmit}>
          <Text color={colors.light} size={16} weight={500}>
            Log In
          </Text>
        </PrimaryButton>

        {signUpSection && (
          <>
            <Indent top={24} />
            <Text textAlign="center" size={14}>
              Don’t have an account?&nbsp;
              <Link size={14} color={colors.primary[500]} onClick={onShowSignUp}>
                Sign Up
              </Link>
            </Text>
          </>
        )}
      </InnerWrapper>
    </Wrapper>
  );
};

export default SignInByEmail;
