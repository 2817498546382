import styled, { css } from 'styled-components';
import { colors } from 'helpers/colors';

const cornerWidth = 20;
const tipTargetIndent = 4;
const cornerHeight = parseInt((Math.sqrt(2) * cornerWidth) / 2);

export const Wrapper = styled.span`
  position: relative;
  cursor: pointer;
  width: fit-content;
  display: inline-block;
`;

export const MessageCorner = styled.span`
  width: ${cornerWidth}px;
  height: ${cornerWidth}px;
  position: absolute;
  left: 50%;
  background-color: ${colors.gray[50]};
  border-top: 1px solid ${colors.gray[200]};
  border-left: 1px solid ${colors.gray[200]};
  z-index: 2;
  ${({ $yAxis }) => {
    if ($yAxis === 'bottom')
      return css`
        top: calc(100% + ${tipTargetIndent}px);
        transform: rotate(45deg) translate(-25%, 50%);
      `;
    if ($yAxis === 'top')
      return css`
        bottom: calc(100% + ${tipTargetIndent}px);
        transform: rotate(225deg) translate(50%, -25%);
      `;
  }}
`;

export const TipWrapper = styled.span`
  position: absolute;
  background-color: ${colors.gray[50]};
  border: 1px solid ${colors.gray[200]};
  padding: 12px 20px 16px;
  border-radius: 16px;
  max-width: 400px;
  width: max-content;
  left: ${({ xOffset }) => `${xOffset}px`};
  z-index: 1;

  ${({ $xAxis, $yAxis }) => {
    let xTranslate = '-50%',
      yTranslate = '100%';
    if ($yAxis === 'top') yTranslate = '-100%';
    if ($xAxis === 'right') xTranslate = `-${cornerHeight * 3}px`;
    // if ($xAxis === 'left') xTranslate = '-85%';

    if ($yAxis === 'top') {
      return css`
        top: calc(-${tipTargetIndent}px - ${cornerHeight}px);
        transform: translate(${xTranslate}, ${yTranslate});
      `;
    }

    return css`
      bottom: calc(-${tipTargetIndent}px - ${cornerHeight}px);
      transform: translate(${xTranslate}, ${yTranslate});
    `;
  }}

  @media (max-width: 560px) {
    max-width: 300px;
  }
`;
